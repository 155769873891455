import "./scss/main.scss";
import { TrackOpTypes, createApp } from 'vue';
import FormCompornent from './components/FormCompornent.vue';
import footer_form from './components/footer-form.vue';
import Swiper from 'swiper/bundle';
import 'swiper/css'; // SwiperのCSSをインポート
const swiper = new Swiper('.swiper-container', {
  loop: true,
  effect: 'fade', // フェードに設定
  slidesPerView: 1, // フェードに設定
  autoplay: { // 自動再生させる
    delay: 8000, // 次のスライドに切り替わるまでの時間（ミリ秒）
    disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
    waitForTransition: false, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
  },
  speed: 2000,
});

const swiper2 = new Swiper('.kennobi-content__list-slider', {
    loop: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: true
    },
    speed: 500,
    slidesPerView: 1.4,
    centeredSlides : true,
    spaceBetween: 10,
});

createApp({
  components: {
    FormCompornent,
  },
  template: '<FormCompornent />',
}).mount('#form');

createApp({
  components: {
    footer_form,
  },
  template: '<footer_form />',
}).mount('#footer_form');

(function(d) {
  var config = {
    kitId: 'niy1cqf',
    scriptTimeout: 3000,
    async: true,
  },
  h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
})(document);

document.addEventListener('DOMContentLoaded', function() {
  const formButton = document.querySelector('.page-photosenryuu__form-button');
  const formWrap = document.querySelector('.form__wrap');
  const closeButton = document.querySelector('.form__crose-button');
  const homebutton = document.querySelector('.form__home-button');

  // フォームを開く・閉じる関数
  function toggleForm() {
    formWrap.classList.toggle('open');
  }

  // ボタンのクリックイベントをリスンし、クラスをトグルする
  formButton.addEventListener('click', toggleForm);
  closeButton.addEventListener('click', toggleForm);
  homebutton.addEventListener('click', toggleForm);
});

  // スムーススクロールを実行する関数
  function smoothScroll(target) {
    const headerHeight = 100; // ヘッダーの高さ（必要に応じて調整してください）
    const element = document.querySelector(target);
    const targetPosition = element.getBoundingClientRect().top;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - headerHeight;
    const duration = 1000; // スクロールの所要時間（ミリ秒）

    let start = null;

    function step(timestamp) {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
      if (progress < duration) window.requestAnimationFrame(step);
    }

    function easeInOutCubic(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t * t + b;
      t -= 2;
      return c / 2 * (t * t * t + 2) + b;
    }

    window.requestAnimationFrame(step);
  }

  // リンククリック時にスムーススクロールを実行
  document.addEventListener('DOMContentLoaded', function() {
    const smoothLinks = document.querySelectorAll('a[href^="#"]');
    for (let i = 0; i < smoothLinks.length; i++) {
      smoothLinks[i].addEventListener('click', function(event) {
        event.preventDefault();
        const target = this.getAttribute('href');
        smoothScroll(target);
      });
    }
  });

  const footerContact = document.getElementById("footerContact");
  const footerForm = document.getElementById("footerForm");
  
  const footerFormClose = document.getElementById("footerFormClose");
  const footerFormClose2 = document.getElementById("footerFormClose2");

  footerContact.addEventListener("click", () => {
    footerForm.classList.toggle("open");
  });

  footerFormClose.addEventListener("click", () => {
    footerForm.classList.toggle("open");
  });

