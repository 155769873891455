<template>
  <div class="footer-form" id="footerForm">
    <div class="footer-form__wrap">
      <h3>お問い合わせ</h3>
      <form class="footer-form__form">
        <label>お名前</label>
        <input type="text" name="footer_form_your-name" v-model="formState.footer_form_your_name" />
        <p v-if="validationErrors.footer_form_your_name" class="error">
          {{ validationErrors.footer_form_your_name }}
        </p>
        <label>メールアドレス</label>
        <input type="text" name="footer_form_your-email" v-model="formState.footer_form_your_email" />
        <p v-if="validationErrors.footer_form_your_email" class="error">
          {{ validationErrors.footer_form_your_email }}
        </p>
        <label>お問い合わせ内容</label>
        <textarea name="footer_form_content" v-model="formState.footer_form_content"></textarea>
        <p v-if="validationErrors.footer_form_content" class="error">
          {{ validationErrors.footer_form_content }}
        </p>
        <button type="button" id="footer-form__submit" :class="{ form__none: isNextButtonDisabled }" @click="sendFormAndShowMessage" :disabled="isNextButtonDisabled">お問い合わせ内容を送信</button>
      </form>
      <button class="footer-form__close-button" id="footerFormClose">×</button>
      <div class="footer-form__success success-message" v-if="showSuccessMessage" >
        <div class="footer-form__success-wrap">
          <h4>お問い合わせありがとうございました。</h4>
          <button class="footer-form__next" id="footer-form__next" @click="resetValidation">他のお問い合わせをする</button>
          <button class="footer-form__close" id="footerFormClose2" @click="closeFooterForm">閉じる</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
  import { reactive, watch, ref, computed, onMounted } from "vue";
  import axios from "axios"; // axiosをインポート

  


  const formState = reactive({
    footer_form_your_name: "",
    footer_form_your_email: "",
    footer_form_content: "",
  });
  
  const validationRules = {
    footer_form_your_name: {
      required: true,
      message: "名前が空です。",
    },
    footer_form_your_email: {
      required: true,
      message: "メールアドレスが空です。",
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      patternMessage: "メールアドレスが無効です。",
    },
    footer_form_content: {
      required: true,
      message: "お問い合わせ内容が空です。",
    },
  };
  
  const validationErrors = reactive({});
  const isFormTouched = ref(false);

const validateForm = () => {
  if (isFormTouched.value) { // フォームがインタラクションした場合のみバリデーションを実行
    Object.keys(validationRules).forEach((field) => {
      const rules = validationRules[field];
      const value = formState[field];

      if (rules.required && !value.trim()) {
        validationErrors[field] = rules.message;
      } else if (rules.pattern && !rules.pattern.test(value)) {
        validationErrors[field] = rules.patternMessage;
      } else {
        validationErrors[field] = null;
      }
    });
  }
};

watch(
  () => formState,
  () => {
    if (isFormTouched.value) { // フォームがインタラクションした場合のみバリデーションを実行
      validateForm();
    }
  },
  { deep: true }
);
onMounted(() => {
  // イニシャルロード時にバリデーションエラーを表示しないように、isFormTouched を true に設定
  isFormTouched.value = true;
});
  const isNextButtonDisabled = computed(() => {
    // If any validation error exists, return true to disable the button
    return Object.values(validationErrors).some((error) => error !== null);
  });
  
  const showSuccessMessage = ref(false);
  
  const sendFormAndShowMessage = async () => {
  isFormTouched.value = true; // フォームがインタラクションしたとみなす
  validateForm(); // フォームのバリデーションを実行

  // フォームが有効な場合のみ処理を行う
  if (!isNextButtonDisabled.value) {
    try {
      // フォームデータを準備
      const formData = {
        footer_form_your_name: formState.footer_form_your_name,
        footer_form_your_email: formState.footer_form_your_email,
        footer_form_content: formState.footer_form_content,
      };

      // axiosを使用して非同期でフォームデータを送信
      await axios.post("https://www.kennobi-tottori.jp/form/contact.php", formData, {
        headers: {
          "Content-Type": "application/json", // JSON形式でデータを送信するためのContent-Typeを設定
        },
      });

      // バリデーションエラーをリセット
      Object.keys(validationErrors).forEach((field) => {
        validationErrors[field] = null;
      });

      // 完了メッセージを表示
      showSuccessMessage.value = true;

      // フォームの内容をクリア
      formState.footer_form_your_name = "";
      formState.footer_form_your_email = "";
      formState.footer_form_content = "";

      // フォームがインタラクションしていない状態にリセット
      isFormTouched.value = false;
    } catch (error) {
      console.error(error);
    }
  } else {
    // フォームが無効な場合は、フォーム送信を中止
    console.log("フォームにエラーがあります。送信を中止します。");
  }
};

const closeFooterForm = () => {
  // 「open」クラスを持つdiv要素から「open」クラスを削除
  const footerForm = document.getElementById("footerForm");
  footerForm.classList.remove("open");

  // showSuccessMessageとshowCloseButtonの状態をリセット
  showSuccessMessage.value = false;
  showCloseButton.value = false;
};

const resetValidation = () => {
  // バリデーションエラーをリセット
  Object.keys(validationErrors).forEach((field) => {
    validationErrors[field] = null;
  });

  // フォームがインタラクションしていない状態にリセット
  isFormTouched.value = false;
  showSuccessMessage.value = false;
};

  </script>
  
  <style>
.form__none{
  opacity:0.7;
}
  </style>