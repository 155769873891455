<template>
  <form class="form" method="post" enctype="multipart/form-data">
    <div style="display: none">
      <input type="hidden" name="_wpcf7" value="16" />
      <input type="hidden" name="_wpcf7_version" value="5.7.7" />
      <input type="hidden" name="_wpcf7_locale" value="ja" />
      <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f16-o1" />
      <input type="hidden" name="_wpcf7_container_post" value="0" />
      <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
    </div>
    <div class="form__progress">
      <div class="form__progress-table">
        <svg>
          <circle class="base" cx="75" cy="75" r="70"></circle>
          <circle
            class="line"
            :style="`stroke-dashoffset: ${progressStrokeDashOffset}`"
            cx="75"
            cy="75"
            r="70"
          ></circle>
        </svg>
      </div>
      <div class="form__step-title font-maru">
        <h4 class="font-maru">STEP</h4>
        <div class="form__step-no">
          <p :class="`step step-${currentStep}`">{{ currentStep }}</p>
          <hr />
          <p>{{ totalSteps }}</p>
        </div>
      </div>
    </div>
    <div id="step1" v-show="currentStep === 1">
      <h2 class="font-maru">応募者の情報を入力</h2>
      <div class="form__input-wrap">
        <label for="name">名前（団体の場合は代表者名）*公表されません</label>
        <input
          name="your-name"
          id="name"
          type="text"
          :value="formState.name"
          @input="onInputForm('name', $event.target.value)"
          @blur="validateField('name')"
          placeholder="健康 太郎"
          :class="{ errortable: validationErrors.name }"
        />
        <p v-if="validationErrors.name" class="error">
          {{ validationErrors.name }}
        </p>
      </div>

      <div class="form__input-wrap">
        <label for="pen-name">ペンネーム（団体の場合は団体名）</label>
        <input
          name="pen-name"
          id="pen-name"
          type="text"
          :value="formState.pen_name"
          @input="onInputForm('pen_name', $event.target.value)"
          @blur="validateField('pen_name')"
          :class="{ errortable: validationErrors.pen_name }"
          placeholder="タロウ"
        />
        <p v-if="validationErrors.pen_name" class="error">
          {{ validationErrors.pen_name }}
        </p>
      </div>

      <div class="form__input-wrap">
        <label for="seibetsu">性別</label>
        <select
          id="seibetsu"
          @input="onInputForm('seibetsu', $event.target.value)"
          @blur="validateField('seibetsu')"
          v-model="formState.seibetsu"
          :class="{ errortable: validationErrors.seibetsu }"
          name="seibetsu"
        >
          <option disabled value="">選択してください</option>
          <option value="男性">男性</option>
          <option value="女性">女性</option>
          <option value="無回答">無回答</option>
        </select>
        <p v-if="validationErrors.seibetsu" class="error">
          {{ validationErrors.seibetsu }}
        </p>
      </div>

      <div class="form__input-wrap">
        <label for="nenrei">年齢</label>
        <input
          name="nenrei"
          id="nenrei"
          type="number"
          min="0"
          max="150"
          :value="formState.nenrei"
          @input="onInputForm('nenrei', $event.target.value)"
          @blur="validateField('nenrei')"
          :class="{ errortable: validationErrors.nenrei }"
          placeholder="半角数字で入力"
        />
        <p v-if="validationErrors.nenrei" class="error">
          {{ validationErrors.nenrei }}
        </p>
      </div>

      <div class="form__input-wrap">
        <label for="city">お住まいの市町村</label>
        <select
          id="city"
          @input="onInputForm('city', $event.target.value)"
          @blur="validateField('city')"
          :class="{ errortable: validationErrors.city }"
          v-model="formState.city"
          name="city"
        >
          <option disabled value="">市町村を選択</option>
          <option value="鳥取市">鳥取市</option>
          <option value="米子市">米子市</option>
          <option value="倉吉市">倉吉市</option>
          <option value="境港市">境港市</option>
          <option value="岩美町">岩美町</option>
          <option value="若桜町">若桜町</option>
          <option value="智頭町">智頭町</option>
          <option value="八頭町">八頭町</option>
          <option value="三朝町">三朝町</option>
          <option value="湯梨浜町">湯梨浜町</option>
          <option value="琴浦町">琴浦町</option>
          <option value="北栄町">北栄町</option>
          <option value="日吉津村">日吉津村</option>
          <option value="大山町">大山町</option>
          <option value="南部町">南部町</option>
          <option value="伯耆町">伯耆町</option>
          <option value="日南町">日南町</option>
          <option value="日野町">日野町</option>
          <option value="江府町">江府町</option>
        </select>
        <p v-if="validationErrors.city" class="error">
          {{ validationErrors.city }}
        </p>
      </div>
      <div class="form__buttons">
        <button
          id="Step1"
          type="button"
          class="form__next-button"
          :class="{ form__none: isNextButtonDisabled }"
          @click="validateStep1"
        >
          応募者情報の入力へ
        </button>
      </div>
    </div>

    <div id="step2" v-show="currentStep === 2">
      <h2 class="font-maru">応募者の情報を入力</h2>
      <div class="form__input-wrap">
        <label for="mail">メールアドレス</label>
        <input
          name="emailaddress"
          id="mail"
          type="text"
          :value="formState.mail"
          :class="{ errortable: validationErrors.mail }"
          @input="onInputForm('mail', $event.target.value)"
          @blur="validateField('mail')"
          placeholder="mail@xxx.jp"
        />
        <p v-if="validationErrors.mail" class="error">
          {{ validationErrors.mail }}
        </p>
      </div>
      <div class="form__input-wrap">
        <label for="tel">電話番号</label>
        <input
          name="telnumber"
          id="tel"
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          :value="formState.tel"
          :class="{ errortable: validationErrors.tel }"
          @input="onInputForm('tel', $event.target.value)"
          @blur="validateField('tel')"
          placeholder="090-XXXX-XXXX"
        />
        <p v-if="validationErrors.tel" class="error">
          {{ validationErrors.tel }}
        </p>
      </div>

      <div class="form__input-wrap">
        <label for="questionnaire">本コンテストをどこで知りましたか？</label>
        <div
          class="form__checkbox-list"
          :class="{ errortable: validationErrors.questionnaire }"
        >
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              v-model="formState.questionnaire"
              value="ポスター"
              name="poster"
              id="poster"
            />
            <p>ポスター</p>
          </div>
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              v-model="formState.questionnaire"
              value="チラシ"
              name="tirashi"
              id="tirashi"
            />
            <p>チラシ</p>
          </div>
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              v-model="formState.questionnaire"
              value="ホームページ"
              name="homepage"
              id="homepage"
            />
            <p>ホームページ</p>
          </div>
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              v-model="formState.questionnaire"
              value="テレビ番組"
              name="tv"
              id="tv"
            />
            <p>テレビ番組</p>
          </div>
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              v-model="formState.questionnaire"
              value="ラジオ番組"
              name="radio"
              id="radio"
            />
            <p>ラジオ番組</p>
          </div>
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              name="cm"
              v-model="formState.questionnaire"
              value="CM"
              id="cm"
            />
            <p>CM</p>
          </div>
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              name="twitter"
              v-model="formState.questionnaire"
              value="SNS広告"
              id="twitter"
            />
            <p>SNS広告</p>
          </div>
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              name="kuchikomi"
              v-model="formState.questionnaire"
              value="友人・知人口コミ"
              id="kuchikomi"
            />
            <p>友人・知人口コミ</p>
          </div>
          <div class="form__checkbox-item">
            <input
              type="checkbox"
              name="other"
              v-model="formState.questionnaire"
              value="その他"
              id="other"
            />
            <p>その他</p>
          </div>
        </div>
        <p v-if="validationErrors.questionnaire" class="error">
          {{ validationErrors.questionnaire }}
        </p>

        <div class="form__checkbox-answer">
          <span v-if="formState.questionnaire.includes('ポスター')"
            >ポスター</span
          >
          <span v-if="formState.questionnaire.includes('チラシ')">チラシ</span>
          <span v-if="formState.questionnaire.includes('ホームページ')"
            >ホームページ</span
          >
          <span v-if="formState.questionnaire.includes('テレビ番組')"
            >テレビ番組</span
          >
          <span v-if="formState.questionnaire.includes('ラジオ番組')"
            >ラジオ番組</span
          >
          <span v-if="formState.questionnaire.includes('CM')">CM</span>
          <span v-if="formState.questionnaire.includes('SNS広告')"
            >SNS広告</span
          >
          <span v-if="formState.questionnaire.includes('友人・知人口コミ')"
            >友人・知人口コミ</span
          >
          <span v-if="formState.questionnaire.includes('その他')">その他</span>
        </div>
      </div>
      <div class="form__input-wrap">
        <label for="questionnaire"
          >その他の回答の場合以下にご記入下さい。</label
        >
        <textarea id="other2" name="other" placeholder="自由回答" v-model="formState.other2"></textarea>
      </div>

      <div class="form__buttons">
        <button type="button" class="form__prev-button" @click="prevStep">
          戻る
        </button>
        <button type="button" class="form__next-button" @click="validateStep2" id="Step2">
          応募者情報の確認へ
        </button>
      </div>
    </div>

    <div id="step3" v-show="currentStep === 3">
      <h2 class="font-maru">応募者の情報を確認</h2>
      <div class="form__input-wrap input-none">
        <label for="name">名前(公表はされません)</label>
        <input id="name" type="text" :value="formState.name" />
        <p v-if="validationErrors.name" class="error">
          {{ validationErrors.name }}
        </p>
      </div>

      <div class="form__input-wrap input-none">
        <label for="pen-name">ペンネーム</label>
        <input
          name="pen-name"
          id="pen-name"
          type="text"
          :value="formState.pen_name"
        />
        <p v-if="validationErrors.pen_name" class="error">
          {{ validationErrors.pen_name }}
        </p>
      </div>

      <div class="form__input-wrap input-none">
        <label for="seibetsu">性別</label>
        <select v-model="formState.seibetsu">
          <option disabled value="">選択してください</option>
          <option value="男性">男性</option>
          <option value="女性">女性</option>
          <option value="無回答">無回答</option>
        </select>
        <p v-if="validationErrors.seibetsu" class="error">
          {{ validationErrors.seibetsu }}
        </p>
      </div>

      <div class="form__input-wrap input-none">
        <label for="nenrei">年齢(半角数字のみ)</label>
        <input type="number" v-model="formState.nenrei" />
        <p v-if="validationErrors.nenrei" class="error">
          {{ validationErrors.nenrei }}
        </p>
      </div>

      <div class="form__input-wrap input-none">
        <label for="city">お住まいの市町村</label>
        <select v-model="formState.city">
          <option disabled value="">市町村を選択</option>
          <option value="鳥取市">鳥取市</option>
          <option value="米子市">米子市</option>
          <option value="倉吉市">倉吉市</option>
          <option value="境港市">境港市</option>
          <option value="岩美町">岩美町</option>
          <option value="若桜町">若桜町</option>
          <option value="智頭町">智頭町</option>
          <option value="八頭町">八頭町</option>
          <option value="三朝町">三朝町</option>
          <option value="湯梨浜町">湯梨浜町</option>
          <option value="琴浦町">琴浦町</option>
          <option value="北栄町">北栄町</option>
          <option value="日吉津村">日吉津村</option>
          <option value="大山町">大山町</option>
          <option value="南部町">南部町</option>
          <option value="伯耆町">伯耆町</option>
          <option value="日南町">日南町</option>
          <option value="日野町">日野町</option>
          <option value="江府町">江府町</option>
        </select>
        <p v-if="validationErrors.city" class="error">
          {{ validationErrors.city }}
        </p>
      </div>

      <div class="form__input-wrap input-none">
        <label for="mail">メールアドレス</label>
        <input type="text" name="your-email" :value="formState.mail" />
        <p v-if="validationErrors.mail" class="error">
          {{ validationErrors.mail }}
        </p>
      </div>
      <div class="form__input-wrap">
        <label for="tel">電話番号</label>
        <input type="number" name="tel-number" :value="formState.tel" />
        <p v-if="validationErrors.tel" class="error">
          {{ validationErrors.tel }}
        </p>
      </div>

      <div class="form__input-wrap input-none">
        <label for="questionnaire">本コンテストをどこで知りましたか？</label>
        <div class="form__checkbox-answer">
          <span v-if="formState.questionnaire.includes('ポスター')"
            >ポスター</span
          >
          <span v-if="formState.questionnaire.includes('チラシ')">チラシ</span>
          <span v-if="formState.questionnaire.includes('ホームページ')"
            >ホームページ</span
          >
          <span v-if="formState.questionnaire.includes('テレビ番組')"
            >テレビ番組</span
          >
          <span v-if="formState.questionnaire.includes('ラジオ番組')"
            >ラジオ番組</span
          >
          <span v-if="formState.questionnaire.includes('CM')">CM</span>
          <span v-if="formState.questionnaire.includes('SNS広告')"
            >SNS広告</span
          >
          <span v-if="formState.questionnaire.includes('友人・知人口コミ')"
            >友人・知人口コミ</span
          >
          <span v-if="formState.questionnaire.includes('その他')">その他</span>
        </div>
      </div>

      <div class="form__input-wrap input-none">
        <label for="questionnaire"
          >その他の回答の場合以下にご記入下さい。</label
        >
        <textarea v-model="formState.other2"></textarea>
      </div>
      <div class="form__buttons">
        <button type="button" class="form__prev-button" @click="prevStep">
          戻る
        </button>
        <button
          type="button"
          class="form__next-button form__red-button"
          @click="validateStep3"
          id="Step3"
        >
          応募作品情報の入力へ
        </button>
      </div>
    </div>

    <div id="step4" v-show="currentStep === 4">
      <h2 class="font-maru">応募作品の情報入力</h2>
      

      <div class="form__input-wrap">
        <label for="work">川柳</label>
        <input
          id="work"
          type="text"
          name="work"
          :value="formState.work"
          @input="onInputForm('work', $event.target.value)"
          @blur="validateField('work')"
          :class="{ errortable: validationErrors.work }"
          placeholder="川柳作品を記入"
        />
        <p v-if="validationErrors.work" class="error">
          {{ validationErrors.work }}
        </p>
      </div>

      <div class="form__input-wrap">
        <label for="work_name">作品名</label>
        <input
          id="work_name"
          type="text"
          name="work_name"
          :value="formState.work_name"
          @input="onInputForm('work_name', $event.target.value)"
          @blur="validateField('work_name')"
          :class="{ errortable: validationErrors.work_name }"
          placeholder="作品名を記入"
        />
        <p v-if="validationErrors.work_name" class="error">
          {{ validationErrors.work_name }}
        </p>
      </div>

      <div class="form__input-wrap">
        <span class="wpcf7-form-control-wrap" data-name="work_img">
          <input
            size="40"
            class="form__file wpcf7-form-control wpcf7-file"
            accept="image/*"
            aria-invalid="false"
            type="file"
            ref="fileInput"
            name="work_img"
            id="work_img"
            @change="handleFileChange"
            @input="onInputForm('work_img', $event.target.value)"
            @blur="validateField('work_img')"
            :class="{ errortable: validationErrors.work_img }"
        /></span>
        <p v-if="validationErrors.work_img" class="error">
          {{ validationErrors.work_img }}
        </p>
        <div v-if="previewImage" class="form__image-preview">
          <img :src="previewImage" alt="Preview" />
        </div>
      </div>
      <div class="form__buttons">
        <button id="Step4" type="button" class="form__next-button" @click="validateStep4">
          応募作品のコメント入力へ
        </button>
      </div>
    </div>

    <div id="step5" v-show="currentStep === 5">
      <h2 class="font-maru">作品コメントの入力</h2>
      <div class="form__input-wrap">
        <label for="work">作品コメント</label>
        <textarea
          name="comment"
          id="comment"
          @input="onInputForm('comment', $event.target.value)"
          @blur="validateField('comment')"
          :value="formState.comment"
          :class="{ errortable: validationErrors.comment }"
        >
        </textarea>
        <p v-if="validationErrors.comment" class="error">
          {{ validationErrors.comment }}
        </p>
      </div>

      <div class="form__buttons">
        <button type="button" class="form__prev-button" @click="prevStep">
          戻る
        </button>
        <button id="Step5" type="button" class="form__next-button" @click="validateStep5">
          応募作品の確認へ
        </button>
      </div>
    </div>

    <div id="step6" v-show="currentStep === 6">
      <h2 class="font-maru">応募作品の情報確認</h2>
      <div class="form__input-wrap input-none">
        <label for="work_name">作品名</label>
        <input
          type="text"
          :value="formState.work_name"
          @input="onInputForm('work_name', $event.target.value)"
        />
      </div>

      <div class="form__input-wrap input-none">
        <label for="work">川柳</label>
        <input
          type="text"
          :value="formState.work"
          @input="onInputForm('work', $event.target.value)"
        />
      </div>

      <div v-if="previewImage" class="form__image-preview">
        <img :src="previewImage" alt="Preview" />
      </div>

      <div class="form__input-wrap input-none">
        <label for="work">作品コメント</label>
        <textarea
          :value="formState.comment"
          @input="onInputForm('comment', $event.target.value)"
        ></textarea>
      </div>

      <div class="form__buttons">
        <button  type="button" class="form__prev-button" @click="prevStep">
          戻る
        </button>

        <button
          class="form__next-button form__red-button"
          type="button"
          value="この内容で応募する"
          @click="validateStep6"
          id="Step6"
        >
          この内容で応募する
        </button>
      </div>
    </div>

    <div id="step7" v-show="currentStep === 7">
      <h2 class="font-maru">応募完了</h2>
      <p class="thanks">作品のご応募をありがとうございます。</p>
      <div class="buttons">
        <button
          type="button"
          class="form__next-button form__no-arrow"
          @click="onemore"
        >
          別の作品を応募する
        </button>
        <button type="button" class="form__next-button form__home-button">
          閉じる
        </button>
        <p class="input-mail">
          ご応募いたいた方には、<br />
          入力したメールアドレスに、<br />
          事務局より受付完了メール（自動転送）を<br />
          お送りいたします。
        </p>
        <p class="other">
          メールが届かない場合、入力に誤りがある可能性があります。再度正しいメールアドレスにてご登録ください。
          <br />
          <br />
          ご利用のメールアドレスによっては受付完了メールが「迷惑メールフォルダ」に入ってしまう場合があります。<br />
          <br />
          迷惑メールフォルダに受付完了メールが届いていないかご確認ください。
          <br />
          <br />
          ドメイン指定受信をされている方は「kennobi-tottori.jp」からのメールを受信可能に設定してください。
        </p>
      </div>
    </div>
  </form>
</template>


<script setup>
import { reactive } from "vue";
import { computed } from "vue";

const isNextButtonDisabled = computed(() => {
  const currentStepFields = Object.keys(validationRules).filter((field) =>
    formState.hasOwnProperty(field)
  );
  return currentStepFields.some((field) => !!validationErrors[field]);
});

const validationRules = {
  name: {
    required: true,
    message: "名前が空です。",
  },
  work_img: {
    required: true,
    message: "画像を投稿してください。",
  },
  work_name: {
    required: true,
    message: "作品名が空です。",
  },
  work: {
    required: true,
    message: "川柳が空です。",
  },
  pen_name: {
    required: true,
    message: "ペンネームが空です。",
  },
  mail: {
    required: true,
    message: "メールアドレスが空です。",
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    patternMessage: "メールアドレスが無効です。",
  },
  nenrei: {
    required: true,
    pattern: /^\d+$/,
    message: "年齢は半角数字のみ入力してください。",
  },
  city: {
    required: true,
    message: "市町村が選択されていません。",
  },
  seibetsu: {
    required: true,
    message: "性別が選択されていません。",
  },
  tel: {
    required: true,
    message: "電話番号を入力してください。",
  },
  questionnaire: {
    required: true,
    message: "どれか最低1つご選択下さい。",
  },
  comment: {
    required: true,
    message: "コメントが空です。",
  },
};

const formState = reactive({
  name: "",
  pen_name: "",
  mail: "",
  tel: "",
  seibetsu: "",
  nenrei: "",
  city: "",
  work_name: "",
  work: "",
  work_img: "",
  questionnaire: [],
  other: "",
  comment: "",
  other2:"",
});

const validationErrors = reactive({});

const validateField = (field) => {
  const rules = validationRules[field];
  const value = formState[field];
  validationErrors[field] = "";

  for (const rule in rules) {
    if (rule === "required") {
      if (rules[rule] && !value) {
        validationErrors[field] = rules.message;
        return; // エラーメッセージがある場合はここで処理を終了します
      }
    }
    if (rule === "pattern") {
      if (rules[rule] && !rules[rule].test(value)) {
        validationErrors[field] = rules.patternMessage;
        return; // エラーメッセージがある場合はここで処理を終了します
      }
    }
  }
};

const validateForm = () => {
  for (const field in validationRules) {
    validateField(field);
  }
};

import { ref } from "vue";

const fileInput = ref(null);
const previewImage = ref(null);

const handleFileChange = () => {
  const file = fileInput.value.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      previewImage.value = reader.result;
    };
    reader.readAsDataURL(file);
  } else {
    previewImage.value = null;
  }
};

const currentStep = ref(1); // 初期ステップは1

if (window.location.hash === "#complete") {
  // アンカーがある場合、currentStepを6に設定してStep 6を表示
  currentStep.value = 7;
}

const onemore = () => {
  const fileInput = document.getElementById("work_img");
  formState.work_name = "";
  formState.work = "";
  formState.work_img = "";
  formState.comment = "";
  fileInput.type = "text";
  fileInput.type = "file";
  // プレビュー画像をクリア
  previewImage.value = null;
  document.getElementById("work_name").value = "";
  document.getElementById("work").value = "";
  document.getElementById("comment").value = "";
  prevStep();
  prevStep();
  prevStep();
};
// ステップを進める関数
const nextStep = () => {
  currentStep.value++;
};

// ステップを戻す関数
const prevStep = () => {
  currentStep.value--;
};

// ステップ1のバリデーション
const validateStep1 = () => {
  validateField("name");
  validateField("pen_name");
  validateField("nenrei");
  validateField("city");
  validateField("seibetsu");

  // エラーチェック
  if (
    !validationErrors.name &&
    !validationErrors.pen_name &&
    !validationErrors.nenrei &&
    !validationErrors.seibetsu &&
    !validationErrors.city
  ) {
    nextStep();
  }
};

// ステップ2のバリデーション
const validateStep2 = () => {
  validateField("mail");
  validateField("tel");

  // questionnaire チェックボックスのバリデーション
  if (!formState.questionnaire || formState.questionnaire.length === 0) {
    validationErrors.questionnaire = validationRules.questionnaire.message;
  } else {
    validationErrors.questionnaire = "";
  }

  // エラーチェック
  if (
    !validationErrors.mail &&
    !validationErrors.tel &&
    !validationErrors.questionnaire
  ) {
    nextStep();
  }
};

// ステップ3のバリデーション
const validateStep3 = () => {
  nextStep();
};

// ステップ4のバリデーション
const validateStep4 = () => {
  // ステップ4のバリデーションロジック
  validateField("work_name");
  validateField("work");
  validateField("work_img");
  if (
    !validationErrors.work_name &&
    !validationErrors.work &&
    !validationErrors.work_img
  ) {
    nextStep();
  }
};

const validateStep5 = () => {
  // ここでは特にバリデーションのチェックは行わない
  validateField("comment");
  if (!validationErrors.comment) {
    nextStep();
  }
};

const validateStep6 = () => {
  let your_name = document.getElementById("name").value;
  let pen_name = document.getElementById("pen-name").value;
  let seibetsu = document.getElementById("seibetsu").value;
  let nenrei = document.getElementById("nenrei").value;
  let city = document.getElementById("city").value;
  let mail = document.getElementById("mail").value;
  let work_name = document.getElementById("work_name").value;
  let work = document.getElementById("work").value;
  let telnumber = document.getElementById("tel").value;
  let poster = document.getElementById("poster").checked;
  let tirashi = document.getElementById("tirashi").checked;
  let homepage = document.getElementById("homepage").checked;
  let comment = document.getElementById("comment").value;
  let tv = document.getElementById("tv").checked;
  let radio = document.getElementById("radio").checked;
  let cm = document.getElementById("cm").checked;
  let twitter = document.getElementById("twitter").checked;
  let other = document.getElementById("other").checked;
  let other2 = document.getElementById("other2").value;
  const fileInput = document.getElementById("work_img"); // ファイル入力フィールド
  const file = fileInput.files[0];
  const formData = new FormData();
  formData.append("work_img", file); // PHP側でファイルを受け取るときのキー名を指定
  formData.append("your_name", your_name);
  formData.append("pen_name", pen_name);
  formData.append("seibetsu", seibetsu);
  formData.append("nenrei", nenrei);
  formData.append("telnumber", telnumber);
  formData.append("city", city);
  formData.append("mail", mail);
  formData.append("work_name", work_name);
  formData.append("work", work);
  formData.append("comment", comment);
  formData.append("poster", poster);
  formData.append("tirashi", tirashi);
  formData.append("homepage", homepage);
  formData.append("tv", tv);
  formData.append("radio", radio);
  formData.append("cm", cm);
  formData.append("twitter", twitter);
  formData.append("other", other);
  formData.append("other2", other2);

  let xhr = new XMLHttpRequest();
  // POSTリクエストを指定
  xhr.open(
    "POST",
    "https://www.kennobi-tottori.jp/form/form-action.php",
    true
  );
  // レスポンスタイプを指定
  xhr.responseType = "json";
  xhr.onload = function () {
    if (xhr.status === 200) {
      // レスポンスを取得
      let response = xhr.response;

      // レスポンスを表示（例: 成功メッセージを表示）
      alert(response.message);
    } else {
      alert("リクエストに失敗しました");
    }
  };
  // リクエストの送信
  xhr.send(formData);
  nextStep();
};

// ステップの総数
const totalSteps = 7;

// 現在のステップ

// プログレスバーの stroke-dasharray と stroke-dashoffset の計算
const progressStrokeDashOffset = computed(() => {
  const stepWidth = 440 / totalSteps; // 440 は元の値
  return 440 - stepWidth * currentStep.value;
});

const onInputForm = (id, value) => {
  formState[id] = value;
};
</script>

<style>
.error {
  color: red;
}

.image-preview {
  width: 200px;
  height: auto;
  margin-top: 10px;
}

.image-preview img {
  max-width: 100%;
  height: auto;
}
</style>